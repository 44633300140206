import React, { Component } from "react";
import { CustomCheckbox } from "Postlogin/Components/CustomCheckbox";
import FormHelperText from "@mui/material/FormHelperText";
import { OutlinedInput, FormControl } from "@mui/material";

export class CustomMultipleChoice extends Component {
  handleStateChange = (e, label) => {
    let { answer } = this.props;
    answer = answer?.length > 0 ? answer : [];
    const { checked } = e.target;
    const index = answer.indexOf(label);
    if (checked) {
      if (index === -1) {
        answer.push(label);
      }
    } else {
      answer.splice(index, 1);
    }
    this.props.handleStateChange(answer);
  };

  renderCheckbox = () => {
    const { answer = [], styleDetails = {}, options, isDisabled } = this.props;
    const retData = [];
    for (let i = 0; i < options.length; i++) {
      const label = options[i];
      retData.push(
        <FormControl variant="outlined" className="checkbox form-group ">
          <CustomCheckbox
            className="common-check-box"
            checked={answer.includes(label)}
            onChange={(e) =>
              !isDisabled ? this.handleStateChange(e, label) : null
            }
            style={{
              boxShadow: `inset 0 0 0 2px ${styleDetails.checkboxColor}`,
              backgroundColor: answer.includes(label)
                ? styleDetails.checkboxColor
                : "",
            }}
            value={label}
          />
          <OutlinedInput
            className="form-control"
            type="text"
            value={label}
            style={{
              color: styleDetails?.optionColor,
            }}
            onClick={() => {
              return !isDisabled
                ? this.handleStateChange(
                    {
                      target: {
                        checked: !answer.includes(label),
                      },
                    },
                    label
                  )
                : null;
            }}
            readOnly
            disabled={isDisabled}
          />
        </FormControl>
      );
    }
    return retData;
  };

  render() {
    let {
      errorMsg,
      details,
      styleDetails = {},
      question,
      description,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-block checkbox-choice-container">
          {this.renderCheckbox()}
        </div>
        {errorMsg ? (
          <FormHelperText
            className="MuiFormHelperText-root Mui-error"
            style={{
              color: styleDetails.errorColor,
              fontSize: styleDetails.errorFontSize,
              fontFamily: styleDetails.errorFontFamily,
            }}
          >
            {errorMsg}
          </FormHelperText>
        ) : (
          <></>
        )}
      </div>
    );
  }
}
