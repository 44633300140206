import React, { Component } from "react";
import { CustomRadio } from "Postlogin/Components/CustomRadio";
import FormHelperText from "@mui/material/FormHelperText";
import { OutlinedInput, FormControl } from "@mui/material";

export class CustomSingleChoice extends Component {
  handleStateChange = (e) => {
    const { value } = e.target;
    this.props.handleStateChange(value);
  };

  renderRadioList = () => {
    let { answer = "", styleDetails = {}, options, isDisabled } = this.props;
    const retData = [];
    for (let i = 0; i < options.length; i++) {
      retData.push(
        <FormControl variant="outlined" className="radio d-block form-group">
          <CustomRadio
            className="radio-buttons"
            value={options[i]}
            checked={answer === options[i]}
            onChange={(e) => (!isDisabled ? this.handleStateChange(e) : null)}
            style={{
              boxShadow: `inset 0 0 0 2px ${styleDetails.radioBtnColor}`,
              backgroundImage:
                answer === options[i] ? styleDetails.radioBtnColor : "",
              backgroundImage:
                answer === options[i]
                  ? `radial-gradient(${styleDetails.radioBtnColor},${styleDetails.radioBtnColor}
                 40%,
                transparent 44%
              ) `
                  : "",
            }}
          />
          <OutlinedInput
            className="form-control"
            type="text"
            value={options[i]}
            readOnly
            onClick={(e) =>
              !isDisabled
                ? this.handleStateChange({ target: { value: options[i] } })
                : null
            }
            style={{
              color: styleDetails?.optionColor,
            }}
          />
        </FormControl>
      );
    }
    return retData;
  };

  render() {
    const {
      errorMsg,
      details,
      description,
      styleDetails = {},
      question,
    } = this.props;
    return (
      <div className="d-block choice-question-container">
        <div className="mb-3">
          <h3
            style={{
              color: styleDetails.questionColor,
              fontSize: `${styleDetails.questionFontSize}px`,
              fontFamily: styleDetails.questionFontFamily,
            }}
          >
            {question} {details?.isMandatory ? "*" : ""}
          </h3>
          <span
            style={{
              color: styleDetails.descriptionColor,
              fontSize: `${styleDetails.descriptionFontSize}px`,
              fontFamily: styleDetails.descriptionFontFamily,
            }}
          >
            {description}
          </span>
        </div>

        <div className="d-block radio-choice-container">
          {this.renderRadioList()}
          {errorMsg ? (
            <FormHelperText
              className="MuiFormHelperText-root Mui-error"
              style={{
                color: styleDetails.errorColor,
                fontSize: styleDetails.errorFontSize,
                fontFamily: styleDetails.errorFontFamily,
              }}
            >
              {errorMsg}
            </FormHelperText>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  }
}
