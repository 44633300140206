import React, { Component } from "react";
import { PAGE_NAME, status } from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "Postlogin/Components/Header";
import { Box } from "@mui/material";
import { getDashboardDetails } from "ReduxStore/Dashboard/DashboardThunk";
import { connect } from "react-redux";
import { Loading } from "Postlogin/Components/Loading";
import { alert } from "Utils/alert";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      dashboardDetails: [],
    };
  }
  componentDidMount() {
    this.props.getDashboardDetails();
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.dashboardDetailsData.status !==
      prevProps.dashboardDetailsData.status
    ) {
      if (this.props.dashboardDetailsData.status === status.SUCCESS) {
        let dashboardDetails = this.props.dashboardDetailsData.data || {};
        if (
          dashboardDetails?.unAnsweredQuestions?.unAsweredOnboardingQuestions
            ?.length
        ) {
          alert.info(
            "Please answer all the remaining onboarding questions to get surveys."
          );
        }
        this.setState({ dashboardDetails });
      } else if (this.props.dashboardDetailsData.status === status.FAILURE) {
        alert.error(
          this.props.dashboardDetailsData?.data === "Rejected"
            ? "There is some internal server error."
            : this.props.dashboardDetailsData?.data
        );
      }
    }
  };
  render() {
    let { dashboardDetails } = this.state;
    let { dashboardDetailsData } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Dashboard",
            page: PAGE_NAME.DASHBOARD,
            backUrl: ``,
          }}
        />
        <div className="dashboard-container">
          <div className="survey-cards ">
            <div className="row g-3 align-items-center">
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="/app/postlogin/eligible-surveys">
                  <Card
                    className={`survey-card ${
                      dashboardDetails?.totalElligibleSurveys
                        ? "survey-Onboarding-card"
                        : ""
                    }  p-3`}
                  >
                    {dashboardDetails?.totalElligibleSurveys ? (
                      <svg
                        height="100%"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          rx="8"
                          ry="8"
                          class="line"
                          height="100%"
                          width="100%"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}
                    <h5 className="survey-title">Eligible Surveys</h5>
                    <div className="survey-number">
                      {dashboardDetailsData.status === status.IN_PROGRESS ? (
                        <Loading />
                      ) : (
                        dashboardDetails?.totalElligibleSurveys || "0"
                      )}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="/app/postlogin/surveyavailable">
                  <Card
                    className={`survey-card ${
                      dashboardDetails?.liveSurveysCount
                        ? "survey-Onboarding-card"
                        : ""
                    }  p-3`}
                  >
                    {dashboardDetails?.liveSurveysCount ? (
                      <svg
                        height="100%"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          rx="8"
                          ry="8"
                          class="line"
                          height="100%"
                          width="100%"
                          //stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}
                    <h5 className="survey-title">Available Surveys</h5>
                    <div className="survey-number">
                      {dashboardDetailsData.status === status.IN_PROGRESS ? (
                        <Loading />
                      ) : (
                        dashboardDetails?.liveSurveysCount || "0"
                      )}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="/app/postlogin/onboarding">
                  <Card
                    className={`survey-card ${
                      dashboardDetails?.unAnsweredQuestionsCount
                        ? "survey-Onboarding-card"
                        : ""
                    }  p-3`}
                  >
                    {dashboardDetails?.unAnsweredQuestionsCount ? (
                      <svg
                        height="100%"
                        width="100%"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          rx="8"
                          ry="8"
                          class="line"
                          height="100%"
                          width="100%"
                          //stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <></>
                    )}

                    <h5 className="survey-title">
                      Remaining Onboarding Questions
                    </h5>
                    <div className="survey-number">
                      {dashboardDetailsData.status === status.IN_PROGRESS ? (
                        <Loading />
                      ) : (
                        dashboardDetails?.unAnsweredQuestionsCount || "0"
                      )}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="#">
                  <Card className="survey-card p-3">
                    <h5 className="survey-title">Total Rewards Earned</h5>
                    <div className="survey-number">
                      {dashboardDetailsData.status === status.IN_PROGRESS ? (
                        <Loading />
                      ) : (
                        dashboardDetails?.rewardsEarnedCount || "0"
                      )}
                    </div>
                  </Card>
                </Link>
              </div>
              <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                <Link to="#">
                  <Card className="survey-card p-3">
                    <h5 className="survey-title">Total Surveys Answered</h5>
                    <div className="survey-number">
                      {dashboardDetailsData.status === status.IN_PROGRESS ? (
                        <Loading />
                      ) : (
                        dashboardDetails?.totalAnsweredSurveysCount || "0"
                      )}
                    </div>
                  </Card>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Box>
    );
  }
}
function mapStateToProps(state) {
  const { dashboardDetailsData } = state.dashboard;
  return {
    dashboardDetailsData,
  };
}

const mapDispatchToProps = {
  getDashboardDetails,
};

const connectedDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
export default navigateRouter(connectedDashboard);
