import { createSlice } from "@reduxjs/toolkit";
import {
  getAllSurvey,
  getSurveyById,
  submitSurvey,
  getEligibleSurvey,
  getSecondaryCriteriaForSurvey,
  saveSecondaryCriteria,
} from "./SurveyThunk";
import { status } from "Constants";

const DashboardSlice = createSlice({
  name: "survey",
  initialState: {
    allSurveyData: {
      status: null,
      data: [],
    },
    surveyDetailsData: {
      status: null,
      data: {},
    },
    submittedSurveyData: {
      status: null,
      data: {},
    },
    getEligibleSurveyRes: {
      status: null,
      data: {},
    },
    getSecondaryCriteriaForSurveyRes: {
      status: null,
      data: {},
    },
    saveSecondaryCriteriaRes: {
      status: null,
      data: {},
    },
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          allSurveyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getAllSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getAllSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          allSurveyData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getSurveyById.pending.toString(), (state, action) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getSurveyById.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getSurveyById.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          surveyDetailsData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(submitSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(submitSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(submitSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          submittedSurveyData: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(getEligibleSurvey.pending.toString(), (state, action) => {
        return {
          ...state,
          getEligibleSurveyRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(getEligibleSurvey.fulfilled.toString(), (state, { payload }) => {
        return {
          ...state,
          getEligibleSurveyRes: {
            status: status.SUCCESS,
            data: payload.data,
          },
        };
      })
      .addCase(getEligibleSurvey.rejected.toString(), (state, { error }) => {
        return {
          ...state,
          getEligibleSurveyRes: {
            status: status.FAILURE,
            data: error?.message,
          },
        };
      })

      .addCase(
        getSecondaryCriteriaForSurvey.pending.toString(),
        (state, action) => {
          return {
            ...state,
            getSecondaryCriteriaForSurveyRes: {
              status: status.IN_PROGRESS,
            },
          };
        }
      )
      .addCase(
        getSecondaryCriteriaForSurvey.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            getSecondaryCriteriaForSurveyRes: {
              status: status.SUCCESS,
              data: payload.data,
            },
          };
        }
      )
      .addCase(
        getSecondaryCriteriaForSurvey.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            getSecondaryCriteriaForSurveyRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      )

      .addCase(saveSecondaryCriteria.pending.toString(), (state, action) => {
        return {
          ...state,
          saveSecondaryCriteriaRes: {
            status: status.IN_PROGRESS,
          },
        };
      })
      .addCase(
        saveSecondaryCriteria.fulfilled.toString(),
        (state, { payload }) => {
          return {
            ...state,
            saveSecondaryCriteriaRes: {
              status: status.SUCCESS,
              data: payload,
            },
          };
        }
      )
      .addCase(
        saveSecondaryCriteria.rejected.toString(),
        (state, { error }) => {
          return {
            ...state,
            saveSecondaryCriteriaRes: {
              status: status.FAILURE,
              data: error?.message,
            },
          };
        }
      );
  },
});

export default DashboardSlice.reducer;
