import React from "react";
import { Box } from "@mui/material";
import Header from "Postlogin/Components/Header";
import { PAGE_NAME, status } from "Constants";
import { getEligibleSurvey } from "ReduxStore/Survey/SurveyThunk";
import { connect } from "react-redux";
import { Loading } from "Postlogin/Components/Loading";
import { navigateRouter } from "Utils/navigateRouter";

class EligibleSurveys extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eligibleSurveys: [],
    };
  }

  componentDidMount = () => {
    this.props.getEligibleSurvey();
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.getEligibleSurveyRes.status !==
        this.props.getEligibleSurveyRes.status &&
      this.props.getEligibleSurveyRes.status === status.SUCCESS
    ) {
      this.setState({ eligibleSurveys: this.props.getEligibleSurveyRes.data });
    }
  };

  render() {
    const { eligibleSurveys } = this.state;
    const { getEligibleSurveyRes } = this.props;
    return (
      <Box className="main-container">
        <Header
          data={{
            header: "Eligible Surveys",
            page: PAGE_NAME.ELIGIBLE_SURVEYS,
            backUrl: ``,
          }}
        />
        <div className="serverAvailable-container">
          {getEligibleSurveyRes.status === status.IN_PROGRESS ? (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <Loading />
            </div>
          ) : eligibleSurveys.length ? (
            eligibleSurveys.map((survey, index) => {
              return (
                <div
                  className="survey-box"
                  key={index}
                  onClick={() => {
                    this.props.navigate(
                      `/app/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}/${survey.surveyId._id}/${survey.surveyId.surveyName}`
                    );
                  }}
                >
                  <p className="survey-name">{survey.surveyId.surveyName}</p>
                  <p className="survey-description">
                    {survey.surveyId.surveyDescription}
                  </p>
                </div>
              );
            })
          ) : (
            <div
              className="text-center d-flex align-items-center justify-content-center"
              style={{ height: "83vh" }}
            >
              <h4 className="description">
                {getEligibleSurveyRes.status === status.FAILURE
                  ? getEligibleSurveyRes?.data === "Rejected"
                    ? "There is some internal server error."
                    : getEligibleSurveyRes?.data
                  : "No survey found."}
              </h4>
            </div>
          )}
        </div>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
  const { getEligibleSurveyRes } = state.survey;
  return { getEligibleSurveyRes };
};

const mapDispatchToProps = { getEligibleSurvey };

export default navigateRouter(
  connect(mapStateToProps, mapDispatchToProps)(EligibleSurveys)
);
