import { createAsyncThunk } from "@reduxjs/toolkit";
import { postLoginService } from "Services";

export const getAllSurvey = createAsyncThunk("getAllSurvey", async (params) => {
  try {
    const url = `/respondent/livesurvey/getall`;
    const response = await postLoginService.get(url);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const getEligibleSurvey = createAsyncThunk(
  "getEligibleSurvey",
  async () => {
    try {
      const url = `/respondent/livesurvey/getElligibleSurveysForRespondent`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const getSurveyById = createAsyncThunk(
  "getSurveyById",
  async (surveyId) => {
    try {
      const url = `/respondent/survey/${surveyId}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const submitSurvey = createAsyncThunk("submitSurvey", async (data) => {
  try {
    const url = `/respondent/feedback`;
    const response = await postLoginService.post(url, data);
    return response;
  } catch (error) {
    throw error.response?.data;
  }
});

export const getSecondaryCriteriaForSurvey = createAsyncThunk(
  "getSecondaryCriteriaForSurvey",
  async (surveyId) => {
    try {
      const url = `/respondent/survey/getSecondaryCriteriaForSurvey/${surveyId}`;
      const response = await postLoginService.get(url);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);

export const saveSecondaryCriteria = createAsyncThunk(
  "saveSecondaryCriteria",
  async (data) => {
    try {
      const url = `/respondent/survey/saveSecondaryCriteria`;
      const response = await postLoginService.post(url, data);
      return response;
    } catch (error) {
      throw error.response?.data;
    }
  }
);
