import React, { Component } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MenuIcon from "@mui/icons-material/Menu";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import PollIcon from "@mui/icons-material/Poll";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
import { AccountBalance } from "@mui/icons-material";
import { handleLogout } from "Utils/HelperFunctions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import { PAGE_NAME } from "Constants";
import { navigateRouter } from "Utils/navigateRouter";
import Logo from "assets/img/logo.png";
import Pullable from "react-pullable";

class Header extends Component {
  constructor() {
    super();
    this.state = {
      surveys: [],
      isPreview: false,
    };
  }

  componentDidMount = () => {
    const url = window.location.href;
    if (url.includes("survey-preview")) {
      this.setState({ isPreview: true });
    }
  };

  handleRefresh = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.location.reload();
        resolve();
      }, 1000);
    });
  };

  sideMenuItems = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: PAGE_NAME.DASHBOARD,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.DASHBOARD}`),
    },
    {
      label: "Eligible Surveys",
      icon: <PollIcon />,
      key: PAGE_NAME.ELIGIBLE_SURVEYS,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.ELIGIBLE_SURVEYS}`),
    },
    {
      label: "Available Surveys",
      icon: <PollIcon />,
      key: PAGE_NAME.SURVEY_AVAILABLE,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.SURVEY_AVAILABLE}`),
    },
    {
      label: "Onboarding",
      icon: <PsychologyAltIcon />,
      key: PAGE_NAME.ONBOARDING_QUESTION,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.ONBOARDING_QUESTION}`),
    },
    {
      label: "Profile",
      icon: <PersonIcon />,
      key: PAGE_NAME.PROFILE,
      onClick: () => this.props.navigate(`/app/postlogin/${PAGE_NAME.PROFILE}`),
    },
    {
      label: "Bank Details",
      icon: <AccountBalance />,
      key: PAGE_NAME.BANK_DETAILS,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.BANK_DETAILS}`),
    },
    {
      label: "Change Password",
      icon: <PersonIcon />,
      key: PAGE_NAME.CHANGE_PASSWORD,
      onClick: () =>
        this.props.navigate(`/app/postlogin/${PAGE_NAME.CHANGE_PASSWORD}`),
    },
    {
      label: "Logout",
      icon: <LogoutIcon />,
      onClick: () => {
        handleLogout();
        window.location.href = `${window.location.origin}/auth/prelogin/login`;
      },
    },
  ];

  toggleDrawer = (open) => {
    this.setState({ open });
  };

  DrawerList = () => {
    let details = this.props?.data || {};
    return (
      <Box
        className="side-drawer"
        role="presentation"
        onClick={() => this.toggleDrawer(false)}
      >
        <div className="drawer-logo">
          <a href="">
            <img src={Logo} alt="" />
          </a>
        </div>
        <List>
          {this.sideMenuItems.map((item, index) => (
            <ListItem key={item.label} disablePadding>
              <ListItemButton
                className={`list-btn ${
                  details.page === item.key ? "active" : ""
                }`}
                onClick={item.onClick}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box className="app-versions">
          <a href="#">v1.0.0</a>
        </Box>
      </Box>
    );
  };
  render() {
    let details = this.props?.data || {};
    const { isPreview } = this.state;
    return (
      <Pullable
        onRefresh={this.handleRefresh}
        fadeSpinner={true}
        centerSpinner={true}
        rotateSpinner={true}
        spinnerSize={30}
        spinnerColor="#4047d4"
      >
        <div className="pullable">
          <div className="header-container">
            <div className="header-inner-container">
              {!isPreview && (
                <div className="d-flex align-items-center justify-content-start">
                  {[PAGE_NAME.SURVEY, PAGE_NAME.TERMS_AND_CONDITIONS].some(
                    (page) => page === details.page
                  ) ? (
                    <button className="icon-btn rounded-circle">
                      <ArrowBackIcon
                        onClick={() => {
                          if (details?.backUrl) {
                            this.props.navigate(details.backUrl);
                          }
                        }}
                      />
                    </button>
                  ) : (
                    <button
                      className="icon-btn rounded-circle"
                      aria-label="open drawer"
                      onClick={() => this.toggleDrawer(true)}
                      edge="start"
                      sx={[this.state.open && { display: "none" }]}
                    >
                      <MenuIcon />
                    </button>
                  )}
                </div>
              )}
              <h3 className="d-inline-block mb-0 text-left">
                {details?.header}
              </h3>
              <Drawer
                open={this.state.open}
                onClose={() => this.toggleDrawer(false)}
              >
                {this.DrawerList()}
              </Drawer>
            </div>
          </div>
        </div>
      </Pullable>
    );
  }
}

export default navigateRouter(Header);
